import * as React from "react"
import { Helmet } from "react-helmet"

interface MetaDataProps {
  title: string;
  url: string;
  description?: string;
  children?: any[] | any;
}

const MetaData = (props: MetaDataProps) => {
  return <Helmet>
    <html lang="hu" />
    <title>{props.title} - Alphapro</title>
    <link rel="icon" href="/favicon.ico" />
    <meta property="title" content={props.title} />
    <meta property="og:title" content={props.title} />
    <meta property="url" content={props.url} />
    <meta property="og:url" content={props.url} />
    <meta property="og:image" content="/images/alpha_logo.png" />
    <meta property="og:description" content={props.description} />
    <meta name="description" content={props.description} />
    <meta property="abstract" content={props.description} />
    {props.children}
  </Helmet>;
};

export default MetaData;
